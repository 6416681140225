	<div class="card__container">
		<div class="card-list">
			@for (item of events; track $index) {
				<app-events-item
					[item]="item"
					(onAction)="navigateToDetail(item.id, item.time_start)"
				></app-events-item>
			}
		</div>
		<p-paginator
			[totalRecords]="totalPage"
			[rows]="15"
			[pageLinkSize]="3"
			[alwaysShow]="false"
			(onPageChange)="loadMoreEvents()"
		>
		</p-paginator>
	</div>

