import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersApiService } from '../../../core/services/api/user/users-api.service';

@Component({
	selector: 'app-user-detail1',
	templateUrl: './user-detail.component.html',
	styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
	public userId!: any;

	public userDetails: any;

	constructor(
		private route: ActivatedRoute,
		private userService: UsersApiService
	) {}

	public ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			this.userId = params.get('id');
			this.userService.getUserById(this.userId).subscribe((data: any) => {
				this.userDetails = data;
			});
		});
	}
}
