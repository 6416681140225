import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'timer',
	pure: false,
})
export class Timer implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public transform(value: any): string {
		return moment(value).format('DD.MM.YYYY');
	}
}
