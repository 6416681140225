import { NgModule } from '@angular/core';
import { OutSideClickDirective } from './outSideClick.directive';
import { DateMaskDirective } from './dateMask.directive';
import { TimeMaskDirective } from './timeMask.directive';

@NgModule({
	declarations: [OutSideClickDirective,DateMaskDirective,TimeMaskDirective],
	exports: [OutSideClickDirective,DateMaskDirective,TimeMaskDirective],
})
export class DirectiveModule {}
