import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import moment from 'moment';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.style.scss'],
})
export class DatePickerComponent implements OnInit {
	@Input() inputDate!: number;

	@Output() eventDate = new EventEmitter<{
		date: string;
		unixDate: string;
	}>();

	public isShowCalendar = false;

	public date!: string;

	public momentDate = moment();

	public ngOnInit(): void {
		console.log(this.inputDate, 'inputDate');
		setTimeout(() => {
			console.log(this.inputDate, 'inputDate');
		}, 2100);
		this.date = this.inputDate
			? moment.unix(this.inputDate).format()
			: this.momentDate.format();
	}

	public showOrHideCalendar(): void {
		this.isShowCalendar = !this.isShowCalendar;
	}

	public setDate(date: moment.Moment): void {
		if (!date) {
			this.isShowCalendar = false;
			return;
		}

		this.date = date.format();
		const unix = moment(date).format('L');
		this.eventDate.emit({
			date: this.date,
			unixDate: unix,
		});
		this.isShowCalendar = false;
	}
}
