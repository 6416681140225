import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MapHeaderService {

	public radiusSubject = new BehaviorSubject<number>(5000);

	public isOpen = new BehaviorSubject<boolean>(false);

	public zoomStatus = new Subject<boolean>();

	constructor() { }

	public setRadius(radius: number): void {
		this.radiusSubject.next(radius);
	}

	public visibleMap(status: boolean): void {
		if (status) {
			this.isOpen.next(true);
		}
		if (!status) {
			this.isOpen.next(false);
		}
	}

}
