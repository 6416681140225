import { Component, HostListener, input, OnInit, output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localStorage.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ColorService } from "../../../core/services/color.service";
import { Events } from '../../../core/interfaces/events';
import { EventsHashService } from '../../../core/services/api/events/EventsHash.service';
import { EventsService } from '../../../core/services/api/events/events.service';
import 'moment/locale/ru';
import { CoordinateService } from '../../../core/services/coordinates/coordinate.service';
import { GeoCodingService } from '../../../core/services/coordinates/geocoding.service';
import { MapComponent } from '../map/map';
import { MapHeaderService } from '../map/map-header/map-header.service';


interface AutoCompleteCompleteEvent {
	originalEvent: Event;
	query: string;
}

@Component({
	selector: 'app-event-card',
	templateUrl: './event-card.component.html',
	styleUrls: ['./event-card.component.scss', './event-detail-subscribers.component.scss'],
	animations: [
		trigger('showMap', [
			transition(':enter', [
				style({ top: '-100px', opacity: 0 }),
				animate('500ms ease-in', style({ top: '0', opacity: 1 }))
			]),
			transition(':leave', [
				animate('500ms ease-out', style({ top: '-100px', opacity: 0 }))
			])
		])
	]

})


export class EventCardComponent implements OnInit {

	@ViewChild('map') map!: MapComponent;

	isTouchUI: boolean = window.innerWidth < 720;

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.checkScreenSize();
	}

	public navigateToEdit = output<any>();

	public sendFormData = output<any>();

	public deleteEventUser = output<any>();

	public createEventUser = output<any>();

	public isEditComponent = input();

	public isCreate = input();

	public modals: { [key: string]: boolean } =
		{
			mapModal: false,
			saveModal: false,
			deleteModal: false,
			showEndDate: false,
			isOpenMap: false,
			createModal: false
		};

	public selectedColor: any;

	public adressEvent!: string;

	public currentUserId!: number;

	public hash!: string;

	public decodeHash!: number[];

	public owner: boolean = false;

	public minDate!: Date;

	public isOpen: boolean = false;

	public event: Events.ItemDetailWS = {
		event_id: 0,
		title: '',
		description: '',
		type: 0,
		status: 0,
		longitude: 0,
		latitude: 0,
		time_start: 0,
		time_end: 0,
		created_time: '',
		creator_id: 0,
		creator_name: '',
		sub_level: 0,
		sub_users: []
	};

	public dataFormEvent = {
		selectedStartDate$: '',
		selectedEndDate$: '',
		title: '',
		description: '',
		eventId: null
	};

	public destroy$ = new Subject<void>();

	public address$ = new Subject<string>();

	public form!: FormGroup;

	public selectName: any = [];

	constructor(private route: ActivatedRoute,
		private eventService: EventsService,
		private eventHashService: EventsHashService,
		private localStorageService: LocalStorageService,
		public colorService: ColorService,
		public coordinateService: CoordinateService,
		private router: Router,
		public geoService: GeoCodingService,
		public mapHeaderService: MapHeaderService
	) {

		this.address$.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				this.coordinateService.searchCoordinateByAddress(value);
			}
		});
		this.form = new FormGroup({
			selectedStartDate: new FormControl('', Validators.required),
			selectedEndDate: new FormControl(''),
			title: new FormControl('', Validators.required),
			description: new FormControl('', Validators.required),
			eventId: new FormControl(null)
		});
		this.minDate = new Date();
	}

	ngOnInit(): void {
		this.geoService.selectCoordinate.subscribe({
			next: v => {
				this.selectName = v.map(item => item.display_name);
			}
		});

		this.route.params.subscribe((params) => {
			if (params['hash']) {
				this.hash = params['hash'];
				this.getEvents();
			}

		});


		this.localStorageService
			.getUserId()
			.then((userId) => {
				this.currentUserId = userId;
			})
			.catch(() => {
				this.currentUserId = 0;
			});
		this.checkScreenSize();
	}

	public checkScreenSize(): void {
		this.isTouchUI = window.innerWidth < 720;
	}

	public getEvents(): void {
		this.decodeHash = this.eventHashService.decodeEventHash(this.hash);
		this.eventService
			.getEvent(this.decodeHash[0], this.decodeHash[1])
			.subscribe({
				next: (data: any) => {
					this.event = data;
					this.dataFormEvent.title = data.title;
					this.dataFormEvent.description = data.description;
					this.dataFormEvent.eventId = data.event_id;
					this.eventService.getAddress(this.event.latitude, this.event.longitude).subscribe({
						next: (val) => {
							this.adressEvent = `${val.address.city} ${val.address.road} д.${val.address.house_number}`;
						}
					});
					this.formatTime(this.event.time_start);

					this.owner = this.event.creator_id === this.currentUserId;
				},
			});
	}

	public formatDate(time: number): string {
		return moment(time * 1000).format('D MMMM');
	}

	public formatTime(time: number): string {
		const formattedStartTime = moment(time * 1000).format('HH:mm');
		return `${formattedStartTime}`;
	}


	public navigateToEditComponent(): void {
		const route = {
			id: this.event.event_id,
			timestart: this.event.time_start
		};
		this.navigateToEdit.emit(route);
	}

	public openAndCloseModal(modal: keyof typeof this.modals): void {
		this.modals[modal] = !this.modals[modal];
	}


	public sendEventFormData(): void {
		if (this.form.valid) {
			const dataEvent = {
				event_id: this.dataFormEvent.eventId,
				title: this.form.value.title,
				description: this.form.value.description,
				status: 1,
				longitude: this.coordinateService.coordinates$.value[0],
				latitude: this.coordinateService.coordinates$.value[1],
				time_start: moment(this.form.value.selectedStartDate).unix(),
				time_end: moment(this.form.value.selectedEndDate).unix(),
				time_zone: "Europe/Astrakhan",
			};
			this.sendFormData.emit(dataEvent);
			this.router.navigate(['/']);
		} else {
			this.form.markAllAsTouched();
		}
	}

	public deleteEvent(event?: boolean): void {
		if (event) {
			const dataEvent = {
				event_id: this.dataFormEvent.eventId,
			};

			this.deleteEventUser.emit(dataEvent);
			this.openAndCloseModal('deleteModal');
			this.router.navigate(['/']);
		}
		else {
			return;
		}
	}

	public createEvent(): void {
		if (this.form.valid) {
			const dataEventCreate = {
				event_id: this.dataFormEvent.eventId,
				title: this.form.value.title,
				description: this.form.value.description,
				status: 1,
				longitude: this.coordinateService.coordinates$.value[0],
				latitude: this.coordinateService.coordinates$.value[1],
				time_start: moment(this.form.value.selectedStartDate).unix(),
				time_zone: "Europe/Astrakhan",
			};
			this.createEventUser.emit(dataEventCreate);
			this.router.navigate(['/']);
		} else {
			this.form.markAllAsTouched();
		}
	}

	public navigateToDetail(id: number): void {
		window.open(`profile/${id}`, '_blank');
	}

	public isInvalid(controlName: string): boolean {
		const control = this.form.get(controlName);
		return control ? control.invalid && control.touched : false;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public getInputStyle() {
		// eslint-disable-next-line max-len
		return this.form.get('selectedStartDate')?.invalid && this.form.get('selectedStartDate')?.touched
			? { 'background': '#ffe6e6', 'border': '2px solid red' }
			: {};
	}

	public searchByAddress(event: AutoCompleteCompleteEvent): void {
		this.address$.next(event.query);
	}

	public selectAdress(event: any): void {

		this.coordinateService.searchCoordinateByAddress(event.value);
	}


}
