<div class="edit-card-title-flex">
	<p style="font-size: 24px;">Выбор вашего местоположения</p>
	<span class="edit-card-hidden-map" (click)="mapHeadedrService.visibleMap(false)">Скрыть</span>
</div>
<div class="edit-card-map-header">
	<ul class="edit-card-radius_btn">
		<li (click)="setRadius(5000)" [class.selected]="selectedRadius === 5000">5км</li>
		<li (click)="setRadius(10000)" [class.selected]="selectedRadius === 10000">10км</li>
		<li (click)="setRadius(25000)" [class.selected]="selectedRadius === 25000">25км</li>
		<li (click)="setRadius(50000)" [class.selected]="selectedRadius === 50000">50км</li>
	</ul>
	<div>
		<ul class="edit-card-control">
			<li class="minus" (click)="zoomOut()"></li>
			<li class="plus" (click)="zoomIn()"></li>
		</ul>
	</div>

</div>
