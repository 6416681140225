import { Events } from 'src/app/core/interfaces/events';
import { IWsMessage } from 'src/app/core/interfaces/websocket/websocket.interfaces';
import { WebsocketService } from '../../core/services/websocket/websocket.service';

export class EventsProfileSubClass {
	public page = 0;

	public showBtn = false;

	public search = false;

	public events: Events.ItemWs[] = [];

	public userId!: number;

	constructor(protected wsService: WebsocketService) {
		wsService.connect('user_events');

		this.wsService
			.on<Events.EventResponseData>()
			.subscribe((val) => {
				this.showBtn = true;
				this.events = val.events;
				this.search = false;
			});
	}

	public getEvent(page: number = 0): void {
		this.sendEventRequest(page);
		this.search = true;
	}

	public sendEventRequest(page: number): void {
		this.wsService.send<IWsMessage<any>>({
			action: 'user_events',
			body: {
				type: 'user',
				user_id: this.userId,
				page,
			}
		});
	}
}
