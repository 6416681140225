import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import moment, { Moment } from 'moment';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-datePicker',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	animations: [
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-in', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 }))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})


export class CalendarComponent {
	currentMonth = moment();

	isCalendarVisible = false;

	monthNames = moment.months();

	public selectedStartDate$ = model<moment.Moment | null>(null);

	public selectedEndDate$ = model<moment.Moment | null>(null);

	public controlForm$ = input<FormControl<string>>();

	toggleCalendar(): void {
		this.isCalendarVisible = !this.isCalendarVisible;
	}

	changeMonth(amount: number): void {
		this.currentMonth.add(amount, 'months');
	}

	selectDate(day: moment.Moment): void {
		if (this.isCurrentMonth(day)) {
			if (this.selectedStartDate$() && this.selectedStartDate$()!.isSame(day, 'day')) {
				this.clearSelection();
			} else if (!this.selectedStartDate$() || (this.selectedStartDate$() && this.selectedEndDate$())) {
				this.setStartDate(day);
			} else {
				this.setEndDate(day);
			}
		}
	}

	clearSelection(): void {
		this.selectedStartDate$.set(null);
		this.selectedEndDate$.set(null);
	}

	setStartDate(day: moment.Moment): void {
		this.selectedStartDate$.set(day);
		this.selectedEndDate$.set(null);
	}

	setEndDate(day: moment.Moment): void {
		if (day.isBefore(this.selectedStartDate$()!)) {
			this.selectedEndDate$.set(this.selectedStartDate$());
			this.selectedStartDate$.set(day);
		} else {
			this.selectedEndDate$.set(day);
		}
	}

	isCurrentMonth(day: moment.Moment): boolean {
		return day.isSame(this.currentMonth, 'month');
	}

	isToday(day: moment.Moment): boolean {
		return day.isSame(moment(), 'day');
	}

	isSelectedStart(day: moment.Moment): boolean {
		return this.selectedStartDate$()?.isSame(day, 'day') ?? false;
	}

	isSelectedEnd(day: moment.Moment): boolean {
		return this.selectedEndDate$()?.isSame(day, 'day') ?? false;
	}

	isInRange(day: moment.Moment): boolean {
		if (this.selectedStartDate$() && this.selectedEndDate$()) {
			return day.isAfter(this.selectedStartDate$()) && day.isBefore(this.selectedEndDate$());
		}
		return false;
	}

	cancelDate(): void {
		this.clearSelection();
		this.isCalendarVisible = false;
	}

	applyDate(): void {
		this.isCalendarVisible = false;
	}

	get daysInMonth(): Moment[] {
		const startOfMonth = this.currentMonth.clone().startOf('month').startOf('isoWeek');
		const endOfMonth = startOfMonth.clone().add(6, 'weeks');
		const days = [];

		for (let day = startOfMonth.clone(); day.isBefore(endOfMonth.clone().add(1, 'day')); day.add(1, 'day')) {
			days.push(day.clone());
		}

		return days.slice(0, 42);
	}

	addDaysToRange(days: number): void {
		if (this.selectedStartDate$() && this.selectedEndDate$()) {
			this.selectedEndDate$.set(this.selectedEndDate$()!.clone().add(days, 'days'));
		}
	}

}
