import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { Events } from 'src/app/core/interfaces/events';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CardModule } from 'primeng/card';
import { SvgComponent } from "../svg/svg";

@Component({
	selector: 'app-events-item',
	standalone: true,
	imports: [CommonModule, CardModule, SvgComponent, ButtonModule],
	templateUrl: './events-item.component.html',
	styleUrl: './events-item.style.scss',
	host: { class: 'event' },
})
export class EventsItemComponent {
	@Input({ required: true }) item!: Events.ItemWs;

	@Output() onAction = new EventEmitter();

	public openDetail(): void {
		this.onAction.emit();
	}

	public openDetailMobile(): void {
		if (window.innerWidth < 480 ) {
			this.openDetail();
		}
	}
}
