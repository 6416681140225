import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { EventsService } from '../../../core/services/api/events/events.service';

@Component({
	selector: 'app-create-event',
	templateUrl: './create-event.component.html',
	styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

	constructor(private eventService: EventsService) { }

	ngOnInit() {
	}

	public createEvent(event: any) {

		const createEvent = {
			title: event.title,
			description: event.description,
			type: 1,
			status: 1,
			longitude: event.longitude,
			latitude: event.latitude,
			time_start: event.time_start,
			time_end:0,
			time_zone: event.time_zone
		};
		console.log(createEvent);
		this.eventService.addOrUpdateEvent(createEvent, 'put').subscribe();
	}

}
