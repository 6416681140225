import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '../../../core/services/api/events/events.service';

interface EditEvent {
	event_id: number
	title: number
	description: string
	type: number
	status: number
	longitude: number
	latitude: number
	time_start: number
	time_end?: number
	time_zone: string,
}

@Component({
	selector: 'app-event-detail-edit',
	templateUrl: './event-detail-edit.component.html',
	styleUrls: ['./event-detail-edit.component.scss']
})
export class EventDetailEditComponent {

	constructor(
		private eventService: EventsService,
		private router: Router
	) { }

	public editSaveEvent(event: any): void {
		console.log(event);
		const editEvent: EditEvent = {
			event_id:event.event_id,
			title: event.title,
			description: event.description,
			type: 1,
			status: 1,
			longitude: event.longitude,
			latitude: event.latitude,
			time_start: event.time_start,
			time_zone: event.time_zone
		};
		if (event.time_end !== '') {
			editEvent.time_end = event.time_end;
		}
		this.eventService.addOrUpdateEvent(editEvent, 'put').subscribe({
			next: (val: any) => {
				this.router.navigate([`/`]);
			}
		});
	}

	public deleteEvent(event: any): void {
		this.eventService.deleteEvent({ event_id: event.event_id }).subscribe({
			next: (val: any) => {
				if (val.status == 200) {
					this.router.navigate(['/profile']);
				}
			},
			error: (error: any) => {
				console.log('error', error);
			}
		});
	}
}
