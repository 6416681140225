<div class="card-list">
	<h2>Подписки:</h2>
</div>
<div class="card-list">
	<article class="card" *ngFor="let item of events">
		<div (click)="navigateToDetail(item.id, item.time_start)">
			<input
				type="hidden"
				id="main-event-{{ item.id }}"
				value="{{ item.id }}"
			/>
			<figure class="card-image"></figure>
			<div class="card-header">
				<a href="#">{{ item.title }}</a>
			</div>
			<div class="card-footer">
				<div class="card-meta success">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						display="block"
						id="Calendar"
					>
						<rect x="2" y="4" width="20" height="18" rx="4" />
						<path d="M8 2v4" />
						<path d="M16 2v4" />
						<path d="M2 10h20" />
					</svg>
					{{ item.time_start | date: 'yyyy-MM-dd HH:mm:ss' }}
				</div>
			</div>
		</div>
	</article>
	<div *ngIf="search" class="card-list">
		<div class="loader"></div>
	</div>
</div>
<div *ngFor="let item of subscriptionUser">
	<li>{{ item.name }}</li>
</div>
<p-button *ngIf="showBtn" class="load-more-button" (click)="loadMoreEvents()">
	загрузить еще
</p-button>
