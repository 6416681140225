import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { UpdateUser, User } from '../../../interfaces/users';
import { ErrorApiResponse, SuccessReturn } from '../../../interfaces/api';
import { Api } from '../api.service';

@Injectable({
	providedIn: 'root',
})
export class UsersApiService {

	constructor(private apiService: Api) {}

	public getUsersByName(
		userName: string
	): Observable<User & ErrorApiResponse> {
		const Url = `user?field=name&val=${userName}`;
		return this.apiService.sendGetRequest<null, User>(Url).pipe(map((value) => value));
	}

	public getUserById(
		userId: number,
		originUserId?: number
	): Observable<User> {
		const url = `user/${originUserId || userId}`;
		return this.apiService.sendGetRequest<null, User>(url).pipe(map((value) => value));
	}

	public updateUser(updateUserField: UpdateUser): Observable<User> {
		return this.apiService.sendPutRequest<UpdateUser, User>(
			'user',
			updateUserField
		).pipe((user) => user);
	}

	public suggestFriendship(
		userId: number,
		recipientId: number
	): Observable<SuccessReturn & ErrorApiResponse> {
		return this.apiService.sendPutRequest<
			{ user_id: number; recipient: number },
			SuccessReturn
		>('connection/suggest', {
			user_id: userId,
			recipient: recipientId,
		}).pipe((suggest) => suggest);
	}

	public approveFriendship(
		userId: number
	): Observable<SuccessReturn & ErrorApiResponse> {
		return this.apiService.sendPostRequest<
			{ connected_user: number; approve: boolean },
			SuccessReturn
		>('connection/approve', {
			connected_user: userId,
			approve: true,
		}).pipe((suggest) => suggest);
	}

	public rejectFriendship(
		userId: number
	): Observable<SuccessReturn & ErrorApiResponse> {
		return this.apiService.sendPostRequest<
			{ connected_user: number; approve: boolean },
			SuccessReturn
		>('connection/approve', {
			connected_user: userId,
			approve: false,
		}).pipe((suggest) => suggest);
	}

	public getUserFreinds(): Observable<any> {
		const Url = `users/connection?hidden=0`;
		return this.apiService.sendGetRequest<null, User>(Url).pipe(map((value) => value));
	}
}
