import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UsersApiService } from '../../../../core/services/api/user/users-api.service';

interface User {
	approve: boolean;
	can_change: boolean;
	show: boolean;
	user_id: number;
	name: string;
}

interface Data {
	users: User[];
}

@Component({
	selector: 'app-tab-tab-friends',
	templateUrl: './tab-friends.component.html',
	styleUrls: ['./tab-friends.component.scss'],
})
export class TabFriendsComponent implements OnInit {
	public userInfo: User[] = [];

	public stopRequest = false;

	constructor(
		protected router: Router,
		private userApiService: UsersApiService
	) {}

	ngOnInit(): void {
		this.userApiService.getUserFreinds().subscribe({
			next: (data: Data) => {
				this.stopRequest = true;
				const approvedUsers = data.users.filter((user) => user);
				if (approvedUsers.length > 0) {
					this.userInfo = approvedUsers;
				}
			},
		});
	}

	public navigateToDetail(id: number): void {
		this.router.navigate([`profile/${id}`]);
	}
}
