<div (click)="toggleCalendar()">
  <div class="date-select">
    <img src="../../../../assets/icons/calendar/State=Calendar.svg" class="date-select-icon" />
	@if (selectedStartDate$() && selectedEndDate$()) {
    <div>
      <div class="date-select-display">
        <div class="date-select-title">Дата</div>
        <div class="data-select-area">
          <input
			class="date-select-input-form"
            dateMask
            type="text"
            [value]="selectedStartDate$()!.format('DD.MM.YYYY')"
          />
          -
          <input
		    class="date-select-input-form"
            dateMask
            type="text"
            [value]="selectedEndDate$()!.format('DD.MM.YYYY')"
          />
        </div>
      </div>
    </div>
}
@if (selectedStartDate$() && !selectedEndDate$()) {
    <div>
      <div class="date-select-single-data">
        <div class="date-select-title">Дата</div>
        <div>
          <input
		    class="date-select-input-form"
            dateMask
            type="text"
            [value]="selectedStartDate$()!.format('DD.MM.YYYY')"
          />
        </div>
      </div>
    </div>
}
@if (!selectedStartDate$()) {
    <div>
      <div class="date-select-single-data data">
        <div>Дата</div>
      </div>
    </div>
}
    <img
      src="../../../../assets/icons/calendar/{{isCalendarVisible ? 'up.svg' : 'down.svg' }}"
      class="date-select-arrow"
    />
  </div>
</div>

@if (isCalendarVisible) {
<div class="calendar" @fade>
  <header class="calendar-sub-container">
    <button class="calendar-month-switch-button" (click)="changeMonth(-1)">
      <img src="../../../../assets/icons/calendar/left.svg" class="calendar-month-switch" />
    </button>
    <h3>{{ monthNames[currentMonth.month()] }} {{ currentMonth.year() }}</h3>
    <button class="calendar-month-switch-button" (click)="changeMonth(1)">
      <img src="../../../../assets/icons/calendar/right.svg" class="calendar-month-switch" />
    </button>
  </header>

  <div class="calendar-days">
    <div class="calendar-day calendar-week-day">Пн</div>
    <div class="calendar-day calendar-week-day">Вт</div>
    <div class="calendar-day calendar-week-day">Ср</div>
    <div class="calendar-day calendar-week-day">Чт</div>
    <div class="calendar-day calendar-week-day">Пт</div>
    <div class="calendar-day calendar-week-day">Сб</div>
    <div class="calendar-day calendar-week-day">Вс</div>

	@for (day of daysInMonth; track $index) {
    <div
      class="calendar-day"
      [class.today]="isToday(day)"
      [class.selected-start]="isSelectedStart(day)"
      [class.selected-end]="isSelectedEnd(day)"
      [class.in-range]="isInRange(day)"
      [class.selected-single]="
        selectedStartDate$() &&
        !selectedEndDate$() &&
        selectedStartDate$()!.isSame(day, 'day')
      "
      [class.not-in-month]="!isCurrentMonth(day)"
      (click)="isCurrentMonth(day) ? selectDate(day) : null"
    >
      {{ day.date() }}
    </div>
}
  </div>
  @if(selectedStartDate$() && selectedEndDate$()){
  <div class="calendar-range-box">
    <button class="calendar-add-range-btn" (click)="addDaysToRange(1)">+ 1 день</button>
    <button class="calendar-add-range-btn" (click)="addDaysToRange(3)">+ 3 дня</button>
    <button class="calendar-add-range-btn" (click)="addDaysToRange(7)">+ 7 дня</button>
  </div>
}

  <div class="calendar-buttons">
    <button (click)="cancelDate()" class="calendar-cancel-button">Отменить</button>
    <button (click)="applyDate()" class="calendar-apply-button">Применить</button>
  </div>
</div>
}
