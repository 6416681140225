<div class="search-filters">
	<div class="search-filters--form" >
		<p-datePicker [touchUI]="isTouchUI"  placeholder="Период поиска" name="event-date-time" [(ngModel)]="selectDate$" [showIcon]="true" [iconDisplay]="'input'" dateFormat="dd.mm.yy" selectionMode="range">
			<ng-template pTemplate="footer">
				<p-button class="p-datepicker-prev hidden">Previous</p-button>
				<p-button class="p-datepicker-next hidden">Next</p-button>
			</ng-template>
		</p-datePicker>
		<p-iconField iconPosition="right">
			<p-inputIcon styleClass="pi pi-map"></p-inputIcon>
			<p-autocomplete
			(completeMethod)="searchByAddress($event)"
			[suggestions]="selectName"
			(onSelect)="selectAdress($event)"
			[fluid]="true"
			[showEmptyMessage]="true"
			[showClear]="false"
			[panelStyle]="{ 'max-width': '100%' }"
			[inputStyle]="{ 'height': '44px','border-radius':'8px','cursor':'pointer' }"
			placeholder=" {{(geoService.currentAdress | async) || 'Адрес'}}">
		</p-autocomplete>
			<small (click)="visibleMap()" >Показать на карте</small>
		</p-iconField>
		<div class="search-filters-btn">
			<p-button class="search-filters-btn__settings-icon" icon="pi pi-sliders-h" severity="secondary" (click)="showDialogSettings()"></p-button>
			<p-button class="search-filters-btn__settings-btn" severity="secondary" (click)="showDialogSettings()">Доп настройки</p-button>
			<p-button type="submit" (click)="submitSearchEvent()">Найти</p-button>
		</div>
	</div>
</div>
<p-dialog header="Доп настройки" [modal]="true" [(visible)]="openSettings" [style]="{ width: '25rem'}">
	Доп настройки
</p-dialog>
