import { EventsService } from '../../../core/services/api/events/events.service';
import { EventsHashService } from '../../../core/services/api/events/EventsHash.service';
EventsService
import {
	Component,
	OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import moment from 'moment';




@Component({
	selector: 'app-event-detail',
	templateUrl: './event-detail.component.html',
	styleUrls: [
		'./event-detail.component.scss',
	],
	providers: [MessageService]
})
export class EventDetailComponent implements OnInit {

	constructor(
		private eventService: EventsService,
		private messageService: MessageService,
		private router:Router,
		private eventHashService:EventsHashService

	) {}

	public ngOnInit(): void {}

	public subscribeRequest(eventId:number,solition:boolean): void {
		this.eventService
			.subscriptionEvent(eventId,solition)
			.subscribe((response: any) => {
				console.log(response)
			});
	}

	public approveSubscribeRequest(eventId:number,solution:boolean, subscriber: number): void {
		this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
		this.eventService
			.approveSubscriptionEvent(eventId, solution, subscriber)
			.subscribe((response: any) => {
				console.log(response)
			});
	}
/* 	public blockedSubscribeRequest(eventId:number,solution:boolean, subscriber: number): void {
		this.eventService
			.blockedSubscriptionEvent(eventId, solution, subscriber)
			.subscribe((response: any) => {
				console.log(response)
			});
	} */
	public navigateToEdit(event:any): void {
		console.log(event)
		const formattedTimestamp = moment(event.timestamp).unix();
		const code = this.eventHashService.encodeEventHash(
			event.id,
			formattedTimestamp
		);
		this.router.navigate([`/event/edit/${code}`]);
	}
}
