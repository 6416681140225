import { Occasion } from 'src/app/core/interfaces/events';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { CoordinateService } from 'src/app/core/services/coordinates/coordinate.service';
import { EventsService } from '../../../core/services/api/events/events.service';
import { ModalComponent } from '../modal-base';
import { EventsHashService } from '../../../core/services/api/events/EventsHash.service';
import { ModalService } from '../../../core/services/modal.service';

interface TimeObj {
	hour: string;
	minute: string;
}
@Component({
	templateUrl: './event.component.html',
	styleUrls: ['./event.style.scss'],
})
export class EventFormComponent extends ModalComponent implements OnInit {
	@Input() override title!: string;

	@Input() data!: any;

	@Input() inputData?: Occasion;

	@ViewChild('close') closeBtn!: ElementRef;

	@ViewChild('slider') slider!: ElementRef;

	public date = new Date();

	private dateStart!: number;

	public erorrRequest = false;

	public successrRequest = false;

	public defaultCoordinates!: any;

	public toogleEventForm = false;

	public count: number = 0;

	private dateEnd!: number;

	public characterCount: number = 0;

	public valTime: TimeObj = {
		hour: '',
		minute: '',
	};

	public valTimeEnd: TimeObj = {
		hour: '',
		minute: '',
	};

	public clockAndDataStart: any = {};

	public isShowEndDate = false;

	public eventForm = new FormGroup({
		title: new FormControl<string>('', { nonNullable: true }),
		description: new FormControl<string>('', { nonNullable: true }),
		geolocation: new FormControl<{
			lat: number;
			lon: number;
		}>(
			{
				lat: 0,
				lon: 0,
			},
			{ nonNullable: true }
		),
		startDate: new FormControl<number>(0, { nonNullable: true }),
		endDate: new FormControl<number>(0, { nonNullable: true }),
	});

	constructor(
		private eventsService: EventsService,
		private coordinateService: CoordinateService,
		private eventHashService: EventsHashService,
		private modalService: ModalService
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.data) {
			this.updateFormWithInputData(this.data);
		} else {
			// Оставить форму пустой
		}
	}

	private updateFormWithInputData(data: any): void {
		try {
			if (data) {
				this.eventForm.patchValue({
					title: data.title || '',
					description: data.description || '',
					geolocation: {
						lat: data.glatitude || 0,
						lon: data.glongitude || 0,
					},
					startDate: data.time_start || '',
					endDate: data.time_end || '',
				});
			}
		} catch (error) {
			console.error('Не удалось обработать входные данные', error);
		}
	}

	public showOrHideEndDate(): void {
		this.isShowEndDate = !this.isShowEndDate;
		this.toogleEventForm = !this.toogleEventForm;
	}

	public getCoordinates(coordinates: number[] | undefined): void {
		this.defaultCoordinates = coordinates;
		if (!coordinates) {
			return;
		}
		this.eventForm.controls.geolocation.setValue({
			lat: coordinates[0],
			lon: coordinates[1],
		});
	}

	public async submitEvent(): Promise<void> {
		const { title, description, geolocation } = this.eventForm.controls;

		const defaultReq = {
			title: title.value,
			description: description.value,
			type: 1,
			status: 1,
			longitude: geolocation.value.lon
				? geolocation.value.lon
				: this.coordinateService.coordinates$.value[0],
			latitude: geolocation.value.lat
				? geolocation.value.lat
				: this.coordinateService.coordinates$.value[1],
			time_start: this.dateStart
				? this.dateStart
				: moment(this.date).unix(),
			time_end: this.dateEnd ? this.dateEnd : 1,
			time_zone: 'Europe/Astrakhan',
		};

		const updateReq = {
			event_id: this.data === undefined ? 0 : this.data.event_id,
			title: title.value,
			description: description.value,
			type: 1,
			status: 1,
			longitude: geolocation.value.lon
				? geolocation.value.lon
				: this.coordinateService.coordinates$.value[0],
			latitude: geolocation.value.lat
				? geolocation.value.lat
				: this.coordinateService.coordinates$.value[1],
			time_start: this.dateStart ? this.dateStart : 0,
			time_end: this.dateEnd ? this.dateEnd : 0,
			time_zone: 'Europe/Astrakhan',
		};

		if (this.data) {
			this.eventsService.addOrUpdateEvent(updateReq, 'post').subscribe({
				next: (val: any) => {
					if (val.code === 2) {
						this.erorrRequest = true;
					} else {
						const formattedTimestamp = moment(
							this.dateStart
						).unix();
						const code = this.eventHashService.encodeEventHash(
							this.data.event_id,
							formattedTimestamp
						);
						window.open(`/event/${code}`);
					}
				},
			});
		} else {
			this.eventsService.addOrUpdateEvent(defaultReq, 'put').subscribe({
				next: (val: any) => {
					if (val.code === 2) {
						this.erorrRequest = true;
					} else {
						const formattedTimestamp = moment(
							this.dateStart
						).unix();
						const code = this.eventHashService.encodeEventHash(
							val.event_id,
							formattedTimestamp
						);
						window.open(`/event/${code}`, '_blank');
					}
				},
			});
		}

		this.modalService.destroyModal();
	}

	public setDateStart(event: { date: string; unixDate: string }): void {
		const dateStart = moment(event.date);
		this.clockAndDataStart = {
			year: dateStart
				? moment(dateStart).format('YYYY')
				: String(this.date.getFullYear()),
			month: dateStart
				? moment(dateStart).format('MM')
				: String(this.date.getMonth()),
			day: dateStart
				? moment(dateStart).format('DD')
				: String(this.date.getDay()),
			hr: this.valTime.hour
				? String(this.valTime.hour)
				: String(this.date.getHours()),
			min: this.valTime.minute
				? String(this.valTime.minute)
				: String(this.date.getMinutes()),
			sec: String(0),
		};

		this.dateStart = this.toUnixTime(
			this.clockAndDataStart.year,
			this.clockAndDataStart.month,
			this.clockAndDataStart.day,
			this.clockAndDataStart.hr,
			this.clockAndDataStart.min,
			this.clockAndDataStart.sec
		);
	}

	public setDateEnd(event: { date: string; unixDate: string }): void {
		const dateEnd = moment(event.date);
		this.clockAndDataStart = {
			year: dateEnd
				? moment(dateEnd).format('YYYY')
				: String(this.date.getFullYear()),
			month: dateEnd
				? moment(dateEnd).format('MM')
				: String(this.date.getMonth()),
			day: dateEnd
				? moment(dateEnd).format('DD')
				: String(this.date.getDay()),
			hr: this.valTimeEnd.hour
				? String(this.valTimeEnd.hour)
				: String(this.date.getHours()),
			min: this.valTimeEnd.minute
				? String(this.valTimeEnd.minute)
				: String(this.date.getMinutes()),
			sec: String(0),
		};

		this.dateEnd = this.toUnixTime(
			this.clockAndDataStart.year,
			this.clockAndDataStart.month,
			this.clockAndDataStart.day,
			this.clockAndDataStart.hr,
			this.clockAndDataStart.min,
			this.clockAndDataStart.sec
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public toUnixTime = (
		year: any,
		month: any,
		day: any,
		hr: any,
		min: any,
		sec: any
	): number => {
		const date = new Date(year, month - 1, day, hr, min, sec);
		return date.getTime() / 1000;
	};

	removeModal(): void {
		this.modalService.destroyModal();
	}

	public nextWindow(): void {
		this.count += 100;
		if (this.count > 300) {
			this.count = 0;
		}
		this.slider.nativeElement.style.left = `${-this.count}%`;
	}

	public prevWindow(): void {
		this.count -= 100;
		if (this.count < 0) {
			this.count = 300;
		}
		this.slider.nativeElement.style.left = `${-this.count}%`;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public setTimeStart(event: any): void {
		const timeStr = event.split(':');
		this.valTime = {
			hour: timeStr[0],
			minute: timeStr[1],
		};

		const dateStart = moment(event.date);
		this.clockAndDataStart = {
			year: dateStart
				? moment(dateStart).format('YYYY')
				: String(this.date.getFullYear()),
			month: dateStart
				? moment(dateStart).format('MM')
				: String(this.date.getMonth()),
			day: dateStart
				? moment(dateStart).format('DD')
				: String(this.date.getDay()),
			hr: this.valTime.hour
				? String(this.valTime.hour)
				: String(this.date.getHours()),
			min: this.valTime.minute
				? String(this.valTime.minute)
				: String(this.date.getMinutes()),
			sec: String(0),
		};

		this.dateStart = this.toUnixTime(
			this.clockAndDataStart.year,
			this.clockAndDataStart.month,
			this.clockAndDataStart.day,
			this.clockAndDataStart.hr,
			this.clockAndDataStart.min,
			this.clockAndDataStart.sec
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public setTimeEnd(event: any): void {
		const timeStr = event.split(':');
		this.valTimeEnd = {
			hour: timeStr[0],
			minute: timeStr[1],
		};

		const dateEnd = moment(event.date);
		this.clockAndDataStart = {
			year: dateEnd
				? moment(dateEnd).format('YYYY')
				: String(this.date.getFullYear()),
			month: dateEnd
				? moment(dateEnd).format('MM')
				: String(this.date.getMonth()),
			day: dateEnd
				? moment(dateEnd).format('DD')
				: String(this.date.getDay()),
			hr: this.valTimeEnd.hour
				? String(this.valTimeEnd.hour)
				: String(this.date.getHours()),
			min: this.valTimeEnd.minute
				? String(this.valTimeEnd.minute)
				: String(this.date.getMinutes()),
			sec: String(0),
		};

		this.dateEnd = this.toUnixTime(
			this.clockAndDataStart.year,
			this.clockAndDataStart.month,
			this.clockAndDataStart.day,
			this.clockAndDataStart.hr,
			this.clockAndDataStart.min,
			this.clockAndDataStart.sec
		);
	}

	public updateCharacterCount(): void {
		const description = this.eventForm.get('description')?.value || '';
		this.characterCount = description.length;
	}
}
