import { Component, OnInit } from '@angular/core';
import { MapHeaderService } from './map-header.service';


@Component({
	selector: 'app-map-header',
	templateUrl: './map-header.component.html',
	styleUrls: ['./map-header.component.scss']
})
export class MapHeaderComponent implements OnInit {

	public selectedRadius: number = 5000;


	constructor(public mapHeadedrService: MapHeaderService) {
	}

	ngOnInit() {
	}

	public setRadius(radius: number): void {
		this.mapHeadedrService.setRadius(radius);
		this.selectedRadius = radius;
	}

	public zoomOut(): void {
		this.mapHeadedrService.zoomStatus.next(false);
	}

	public zoomIn(): void {
		this.mapHeadedrService.zoomStatus.next(true);
	}
}
