<div>
	<div class="input_time">
		<form>
			<input
				type="time"
				min="00:00"
				max="23:59"
				required
				(click)="isOpen = !isOpen"
				class="time_now"
				[formControl]="control"
			/>
		</form>
	</div>
</div>
