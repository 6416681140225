import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class EventsHashService {

	public decodeEventHash(hash: string): number[] {
		let encodedStr = '';
		try {
			encodedStr = atob(hash);
		} catch (_) {
			return [];
		}
		const result = encodedStr.split(';');
		if (result.length !== 2) {
			return [];
		}
		const id = Number(result[0]);
		const timestamp = Number(result[1]);
		if (id === 0 || timestamp === 0) {
			return [];
		}
		return [id, timestamp];
	}

	public encodeEventHash(eventId: number, timestamp: number): string {
		const encodedStr = `${eventId};${timestamp}`;
		return btoa(encodedStr);
	}
}
