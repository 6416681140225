import { NgModule } from '@angular/core';
import { EventIcon } from './event-icon.pipe';
import { Timer } from './time';
import { TimeOfDay } from './time-of-day.pipe';
import { CoordinatesPipe } from './coordinates.pipe';

@NgModule({
	declarations: [Timer, EventIcon, TimeOfDay, CoordinatesPipe],
	exports: [Timer, EventIcon, TimeOfDay, CoordinatesPipe],
})
export class PipeModule {}
