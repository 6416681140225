import { AfterViewInit, OnChanges , Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import moment from 'moment';
import { ModalComponent } from '../../modal/modal-base';

@Component({
	selector: 'app-clock',
	templateUrl: './clock.component.html',
	styleUrls: ['./clock.style.scss'],
})
export class ClockComponent extends ModalComponent implements AfterViewInit {
	@Input() eventTime!: number;

	@Output() emitTime = new EventEmitter<string>();

	public isOpen = false;

	public timeNow: string = moment().format('LT');

	public hour!: number;

	public minute!: number;

	public control: FormControl = new FormControl('');

	public ngAfterViewInit(): void {
		console.log(this.eventTime);

		this.control.setValue(
			this.eventTime
				? moment.unix(this.eventTime).format('LT')
				: this.timeNow
		);
		this.control.valueChanges.subscribe((v) =>
			this.emitTime.emit(this.control ? v : this.timeNow)
		);
	}
}
