<div class="modal" #close>
	<div class="wrapper">
		<div class="modal-header">
			<p class="modal-header__text">Настройка события</p>
			<p class="close" (click)="removeModal()">Закрыть</p>
		</div>
		<form class="event-form-container" [formGroup]="eventForm">
			<div class="slider-container">
				<div class="slider" #slider>
					<!-- 1 -->
					<div class="modal-part modal-part-1">
						<div class="modal-block">
							<label class="modal-block__label">Название:</label>
							<div class="modal-block__actions">
								<input
									class="modal-block__input"
									formControlName="title"
									type="text"
								/>
								<p class="modal-block__tip">До 30 символов</p>
							</div>
						</div>
						<div class="modal-block">
							<label class="modal-block__label"> Описание:</label>
							<div class="modal-block__actions">
								<textarea
									class="modal-block__input"
									id="desc_area"
									formControlName="description"
									maxlength="300"
									(input)="updateCharacterCount()"
								></textarea>
								<p class="modal-block__tip">
									Кол-во символов {{ characterCount }} / 300
								</p>
							</div>
						</div>
					</div>
					<!-- 2 -->
					<div class="modal-part modal-part-2">
						<div class="modal-block">
							<label class="modal-block__label"
								>Координаты:</label
							>
							<p class="modal-block-link modal-block__actions">
								{{
									eventForm.controls.geolocation.value.lat
										| coordinates
								}}
								-
								{{
									eventForm.controls.geolocation.value.lon
										| coordinates
								}}
							</p>
						</div>
						<app-map (closeMap)="getCoordinates($event)"></app-map>
					</div>
					<!-- 3 -->
					<div class="modal-part modal-part-3">
						<div class="modal-block">
							<label class="modal-block__label"
								>Дата:&nbsp;&nbsp;&nbsp;</label
							>
							<app-date-picker
								[inputDate]="eventForm.controls.startDate.value"
								(eventDate)="setDateStart($event)"
							></app-date-picker>
						</div>
						<div class="modal-block">
							<label class="modal-block__label">Время:</label>
							<app-clock
								[eventTime]="eventForm.controls.startDate.value"
								(emitTime)="setTimeStart($event)"
							></app-clock>
						</div>
						<div class="modal-block-inline">
							<input
								class="modal-block-inline-checkbox"
								[checked]="isShowEndDate"
								(click)="showOrHideEndDate()"
								type="checkbox"
								id="modal-event-checkbox-end"
							/>
							<div class="modal-block-inline-checkmark"></div>
							<label
								class="modal-block-inline-checkbox-label"
								for="modal-event-checkbox-end"
								>Добавить время окончания события</label
							>
						</div>
						<div class="modal-block" *ngIf="isShowEndDate">
							<label class="modal-block__label"
								>Дата:&nbsp;&nbsp;&nbsp;</label
							>
							<app-date-picker
								[inputDate]="eventForm.controls.endDate.value"
								(eventDate)="setDateEnd($event)"
							></app-date-picker>
						</div>
						<div class="modal-block" *ngIf="isShowEndDate">
							<label class="modal-block__label">Время:</label>
							<app-clock
								[eventTime]="eventForm.controls.endDate.value"
								(emitTime)="setTimeEnd($event)"
							></app-clock>
						</div>
					</div>
					<!-- 4 -->
					<div class="modal-part modal-part-4">
						<button class="create-btn" (click)="submitEvent()">
							{{ title === 'Edit' ? 'Сохранить' : 'Создать' }}
						</button>
						<small *ngIf="successrRequest"
							>Событие успешно создано</small
						>
						<small *ngIf="erorrRequest"
							>ошибка создания события</small
						>
					</div>
				</div>
			</div>
		</form>
		<div class="event-navigation">
			<p class="step-part btn">
				<span (click)="prevWindow()" *ngIf="count !== 0">назад</span>
			</p>
			<div
				class="step-part"
				ngClass="{{ count === 0 ? 'step-part-1' : 'step-part-2' }}"
			>
				1
			</div>
			<div
				class="step-part"
				ngClass="{{ count === 100 ? 'step-part-1' : 'step-part-2' }}"
			>
				2
			</div>
			<div
				class="step-part"
				ngClass="{{ count === 200 ? 'step-part-1' : 'step-part-2' }}"
			>
				3
			</div>
			<div
				class="step-part"
				ngClass="{{ count === 300 ? 'step-part-1' : 'step-part-2' }}"
			>
				4
			</div>
			<div class="step-part btn">
				<span (click)="nextWindow()">далее</span>
			</div>
		</div>
	</div>
</div>
