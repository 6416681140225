<div class="profile-card">
	<div class="profile-card__left">
	</div>
	<img class="profile-card__avatar" src="../../../../assets/images/profile.png">
	<div class="profile-card__center">
		<h2 class="profile-card__name">{{ user.name }}</h2>
		<div class="profile-card__smileys">
			<span *ngFor="let item of user.features">{{ item }}</span>
		</div>
		<p class="profile-card__description_header"> Обо мне: </p>
		<p class="profile-card__description">{{ user.description }}</p>
		<a href="{{ user.link }}" class="profile-card__social">Мои социальные сети</a>

	</div>
	<div class="profile-card__right">
		<p-button
			icon="pi pi-pencil" [rounded]="true" [outlined]="true" severity="secondary"
			(click)="console.log();">
		</p-button>
	</div>
	<div class="edit-profile-mobile">
		@if (currentUserId === user.id) {
				<p-button> Редактировать </p-button>
				<p-button> Выйти </p-button>
		} @else {
			<p-button> Добавить в друзья </p-button>
		}
	</div>

</div>
