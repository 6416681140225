import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ColorService {

	public colors = [
		{ id: 1, name: "Настольные игры", hex: '#FFC67D' },
		{ id: 2, name: "Поездка", hex: '#B2E6CE' },
		{ id: 3, name: "Учёба", hex: '#660066' },
		{ id: 4, name: "Спорт", hex: '#F2C464' },
		{ id: 5, name: "Другое", hex: '#3498DB' }
	];
}
