import {
	AfterViewInit,
	Component,
	OnDestroy,
	EventEmitter,
	Output,
	Input
} from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject, distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { CoordinateService } from 'src/app/core/services/coordinates/coordinate.service';
import { MapHeaderService } from './map-header/map-header.service';
import { GeoCodingService } from '../../../core/services/coordinates/geocoding.service';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.style.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {

	@Input() visibleControl = false;

	@Input() visibleRadius = false;

	private map!: L.Map;

	public readonly radius = {
		14: 1500,
		13: 3000,
		12: 6000,
		11: 9000,
		10: 12000,
		9: 30000,
	};

	public currentZoom = '5';

	private marker!: L.Marker;

	private myPositionMarker!: L.Marker;

	private position$ = new BehaviorSubject<[] | number[]>(this.coordinateService.coordinates$.value);

	private circle!: L.Circle;

	private control!: L.Control;

	private destroy$ = new Subject<void>();

	@Output() closeMap = new EventEmitter<number[] | undefined>(undefined);

	constructor(
		private coordinateService: CoordinateService,
		private mapHeaderService: MapHeaderService,
		private geoService: GeoCodingService
	) {
		// fix icon map
		const iconRetinaUrl = '../../../assets/images/map/marker-icon-2x.png';
		const iconUrl = '../../../assets/images/map/marker-icon.png';
		const shadowUrl = '../../../assets/images/map/marker-shadow.png';
		const iconDefault = L.icon({
			iconRetinaUrl,
			iconUrl,
			shadowUrl,
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			tooltipAnchor: [16, -28],
			shadowSize: [41, 41],
		});
		L.Marker.prototype.options.icon = iconDefault;
	}

	public ngAfterViewInit(): void {
		this.initMap(this.coordinateService.coordinates$.value).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.coordinateService.coordinates$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe({
					next: (value) => {
						if (value) {
							this.position$.next(value);
							this.map.setView(value as L.LatLngExpression);
							this.addMyPositionMarker();
							if (this.visibleRadius) {
								this.updateMapRadius(this.mapHeaderService.radiusSubject.value);
							}
						}
					}
				});
			}
		});
		if (this.visibleRadius) {
			this.mapHeaderService.radiusSubject.pipe(takeUntil(this.destroy$)).subscribe((newRadius) => {
				this.updateMapRadius(newRadius);
			});
		}

		this.mapHeaderService.zoomStatus.subscribe({
			next: (status): any => {
				if (status) {
					this.map.zoomIn();
				}
				if (!status) {
					this.map.zoomOut();
				}
			}
		});

	}

	private updateMapRadius(radius: number): void {
		if (this.circle) {
			this.map.removeLayer(this.circle);
		}
		this.circle = L.circle(this.position$.value as L.LatLngExpression, {
			radius,
			color: '#007BFF',
			fillOpacity: 0,
		}).addTo(this.map);
		this.map.fitBounds(this.circle.getBounds());
	}





	private initMap(coordinates: number[]): Observable<void> {
		return new Observable<void>((subscriber) => {
			this.map = L.map('map', {
				zoomControl: this.visibleControl,
				center: coordinates as L.LatLngExpression,
				zoom: 14,
				attributionControl: false,
			});

			const tiles = L.tileLayer(
				'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				{
					maxZoom: 14,
					minZoom: 3,
					attribution:
						'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				}
			);

			tiles.addTo(this.map);
			this.eventListenerClickMap();
			this.listenZoom();
			subscriber.next();
			subscriber.complete();
		});
	}

	private eventListenerClickMap(): void {
		this.map!.on('click', ({ latlng }) => {
			this.geoService.getAddressIntoCoordinate(latlng);
			const { lat, lng } = latlng;
			this.coordinateService.coordinates$.next([lat, lng]);
			if (this.marker) {
				this.map!.removeLayer(this.marker);
			}
		});
	}



	private addMarker(coordinates: number[], message = ''): void {
		this.marker = L.marker(coordinates as L.LatLngExpression);
		this.marker.addTo(this.map!).bindPopup(message).openPopup();
	}

	private addMyPositionMarker(): void {
		if (this.myPositionMarker) {
			this.myPositionMarker.remove();
		}

		this.myPositionMarker = L.marker(this.position$.value as L.LatLngExpression);
		this.myPositionMarker.addTo(this.map!).openPopup();

	}


	private listenZoom(): void {
		this.map.addEventListener('zoom', () => {
			this.currentZoom = this.map.getZoom().toString();
		});
	}

	public submitPosition(): void {
		this.closeMap.emit(this.position$.value);
	}

	public removeRadius(): void {
		if (this.circle) {
			this.map.removeLayer(this.circle);
		}
	}


	public ngOnDestroy(): void {
		this.position$.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
